
.match__card{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    border-radius: 0;
}

.match__card > * {
    padding: 20px 1rem;
}

.match__card > *:nth-child(1){
    display: block;
    grid-column-start: span 1;
}

.match__card > *:nth-child(1)::before{
    counter-increment: match__counter;
    content: counter(match__counter);
}

.match__card > *:nth-child(2){
    grid-column-start: span 4;
}

.match__card > *:nth-child(3){
    display: block;
    grid-column-start: span 2;
}

.match__card > *:nth-child(4){
    display: block;
    grid-column-start: span 2;
}

.match__card > *:nth-child(5){
    grid-column-start: span 3;
}

.match__teams{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    align-items: center;
    gap: 1rem;
    width: 90%;
    padding: 0;
}

.match__teams > *:nth-child(1){
    grid-column: 1/3;
}

.match__teams > *:nth-child(2){
    grid-column: 3/4;
}

.match__teams > *:nth-child(3){
    grid-column: 4/6;
}

.match__contestant{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .375rem;
    text-align: center;
}

.logo{
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.name{
    font-size: 16px;
    font-weight: 500;
    color: var(--grey-1000);
}


.match__action__group{
    display: flex;
    justify-content: center;
    align-items: center;
}

.match__select__button{
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    width: 70%;
    border-radius: 5px;
    background: var(--grey-600);
    color: var(--white);
}

.match__remove__button{
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    width: 70%;
    border-radius: 5px;
    border: 1px solid var(--red-dark);
    color: var(--red-dark);
}

.selected{
    background: var(--secondary);
}