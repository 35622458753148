.input__container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: end;
  width: 100%;
  padding: 50px;
}
.textarea__container {
  width: 100%;
  padding-top: 10px;
  padding: 50px;
}
.textarea__container textarea {
  width: 100%;
  height: 300px;
  padding: 30px;
  border: 1px solid #c3c3c3;
  font-size: 16px;
  border-radius: 10px;
}
.input__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 75%;
  border-radius: 10px;
  overflow: hidden;
}
.video__container {
  width: 25%;
  height: 250px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border: 5px dotted #008f8f;
}
.video__container img {
  width: 50px;
  height: 50px;
}
.video__container h4 {
  width: 150px;
  text-align: center;
  color: #c3c3c3;
  font-weight: 500;
}
.input__field {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #c3c3c3;
  color: #c3c3c3;
  padding: 30px;
}
.button__container {
  padding: 50px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.previewbutton__container {
  padding: 50px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 40px;
}
.upload {
  background-color: #51ffff;
  padding-left: 50px;
  width: 193px;
  padding-right: 50px;
  padding: 20px;
}

.preview {
  background-color: #bdbdbd;
  color: white;
  padding-left: 50px;
  padding-right: 50px;
  padding: 20px;
  width: 193px;
}
.preview__details {
  margin-left: 50px;
  margin-right: 50px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}
.preview__details img {
  width: 100%;
}
.preview__details h2 {
  font-size: 28px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.preview__details p {
  font-size: 16px;
  line-height: 28px;
}
.video {
  width: 100%;
  height: auto; /* This ensures the video maintains its aspect ratio */
}

@media (max-width: 768px) {
  .input__container {
    display: flex;
    flex-direction: column;
  }
  .video__container {
    width: 100%;
  }
  .input__box {
    width: 100%;
  }
  .input__container {
    padding: 20px;
  }
  .textarea__container {
    padding: 20px;
  }
  .button__container {
    padding: 20px;
  }
  .preview__details {
    margin-left: 0px;
    margin-right: 0px;
  }
  .previewbutton__container {
    padding: 20px;
  }
}
