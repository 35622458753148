
.wrapper{
    display: flex;
    height: 100vh;
    overflow: auto;
    background: var(--white);
}

.content{
    margin-left: auto;
    width: 100%;
    min-height: 100%;
    padding-bottom: 100px;
}

.padder{
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
}

@media (min-width: 1024px) {

    .wrapper{
        /* background: var(--grey-300); */
        background: #F4F4F4;
    }

    .content{
        width: calc(100% - var(--sidebar-width));
    }

    .padder{
        display: none;
    }

}