.content {
  padding: 1rem;
}

.create__notification {
  display: flex;
  justify-content: flex-end;
}

.create__button {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  font-size: small;
  font-weight: 600;
  color: var(--primary-50);
}

.table {
  width: 100%;
  border: none;
  border-collapse: collapse;
  border-radius: 12px;
  overflow: hidden;
}

.table thead {
  background: var(--white);
  color: var(--primary-50);
  text-align: left;
}

.table tbody {
  background: var(--white);
  counter-reset: notification__counter;
}

.table tbody tr {
  border-top: 1px solid var(--grey-50);
}

.table tbody tr > td:first-child::before {
  counter-increment: notification__counter;
  content: counter(notification__counter);
}

.table td,
.table th {
  padding: 1rem 1.5rem;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.edit__icon {
  color: #51bdff;
  font-size: larger;
}

.delete__icon {
  color: #bd2834;
  font-size: larger;
}

/* Create Notification */

.ads__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1.5rem 1rem;
  background: var(--white);
  border-radius: 15px;
}

.wrapper {
  padding: 1.5rem 0rem;
  background: var(--white);
  border-radius: 15px;
}

.hr {
  border: none;
  border-bottom: 1px solid #000;
  border-color: var(--grey-300);
}

.card {
  position: relative;
  width: 100%;
  padding: 0.625rem;
  box-shadow: var(--shadow-4);
  border-radius: 10px;
}

.card__media {
  position: relative;
  overflow: hidden;
}

.card__media > * {
  position: relative;
  width: 100%;
  height: 140px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  overflow: hidden;
}

.card__details {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 1rem 0;
  font-size: small;
}

.card__company {
  color: var(--primary-50);
  font-weight: 700;
}

.card__date {
  color: var(--grey-600);
  font-weight: 600;
}

.icon {
  position: absolute;
  top: -0.625rem;
  right: -0.625rem;
  z-index: 1;
}

.buttons_group {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 0 1rem;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  padding: 0.75rem 0;
  font-size: small;
  font-weight: 500;
}

.active_button {
  background-color: var(--secondary);
  color: var(--black);
}

.disabled_button {
  background-color: var(--grey-600);
  color: var(--white);
}

.form__fields {
  display: block;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem;
  align-items: center;
}

.form__fields :first-child {
  grid-column: span 4 / span 4;
}

.form__fields :last-child {
  grid-column: span 2 / span 2;
}

.flex__fields {
  display: flex;
  gap: 1rem;
}

.input__field {
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  border: 1px solid var(--grey-600);
  border-radius: 5px;
  /* font-size:; */
}

.input__checkbox {
  width: 1rem;
  width: 1rem;
  display: block;
  padding: 1rem 2rem;
  border: 1px solid var(--grey-600);
  border-radius: 5px;
  /* font-size:; */
}

.form_group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.user__option{
    display: flex;
    gap: 1rem;
}

.user__option img{
    width: 20px;
    height: 20px;
    
}

.drop__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  backdrop-filter: blur(3px);
}

.upload__field {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  min-height: 250px;
  max-height: 400px;
  border: 2px dashed var(--black);
  border-radius: 20px;
  box-shadow: var(--shadow-4);
  z-index: 999;
}

.upload__text {
  font-size: small;
  text-align: center;
  color: var(--grey-600);
}

.upload__text span {
  color: var(--primary-50);
  font-weight: 600;
}

.uploaded__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: small;
  text-align: center;
  color: var(--grey-1000);
}

.uploaded__text span:last-child {
  color: var(--red-dark);
  font-weight: 600;
}

input[hidden] {
  display: none;
}

.create__buttons {
  width: 100%;
  margin: auto;
}

.preview__section__heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.phone {
  position: relative;
  display: inline-block;
  width: 100%;
  isolation: isolate;
}

.phone__overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: #00000075;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: 1;
}

.phone > img {
  width: 100%;
  height: auto;
  z-index: 0;
}

.phone .notification {
  position: absolute;
  top: 2rem;
  left: 0rem;
  right: 0rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  width: 90%;
  margin: auto;
  padding: 1rem;
  border-radius: 10px;
  font-size: smaller;
  background: #ffffffe7;
  box-shadow: var(--shadow-4);
  z-index: 2;
}

.phone .notification > img {
  flex-shrink: 0;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  overflow: hidden;
}

.remove__ad__icon {
  position: absolute;
  top: 0rem;
  left: 0rem;
  display: grid;
  place-content: center;
  width: 2rem;
  height: 2rem;
  background: var(--red-dark);
  border-radius: 5px;
}

.remove__ad__icon path {
  fill: var(--red-light);
}

@media (min-width: 1024px) {
  .content {
    padding: 2rem;
  }

  .container {
    padding: 1rem;
  }

  .wrapper {
    padding: 1.5rem 1rem;
  }

  .ads__wrapper {
    flex-direction: column;
    border-radius: 10px;
  }

  .form__fields {
    display: grid;
  }

  .create__buttons {
    width: 50%;
  }

  .phone {
    width: 40%;
  }
}
