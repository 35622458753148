.filter__box{
    display: flex;
    padding: 1rem;
    box-shadow: var(--shadow-2);
    gap: .5rem;
}

.filter__input{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
    border: .5px solid var(--grey-600);
    padding: 0 12px;
    border-radius: 10px;
    width: 100%;
}

.filter__input input{
    font-size: 16px;
}

.club__name{
    display: block;
    padding: 10px;
}

.filter__icon{
    width: 50px;
    height: 50px;
    display: grid;
    place-content: center;
    flex-shrink: 0;
    padding: 10px 12px;
    border: .5px solid var(--grey-600);
    border-radius: 10px;
}