
.login__form{
    display: flex;
    flex-direction: column;
    gap: 3rem 0;
    width: 100%;
}

label{
    font-size: 14px;
}

.form__heading{
    font-size: 22px;
}

.form__subtitle{
    font-size: 12px;
    color: var(--grey-600);
}

.form__input__group > *{
    margin-bottom: 1.5rem;
}

.form__input__group > *:last-child{
    margin-bottom: 0;
}

.form__primary__button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    background: var(--secondary);
    width: 100%;
    padding: 16px 74px;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 14px;
}

.form__checkbox{
    appearance: none;
    width: 18px;
    height: 18px;
    padding: 0;
    border-radius: 2px;
    border: 2px solid var(--Gray-2, #4F4F4F);
}

.form__checkbox:checked{
    background-image: url("data:image/svg+xml,%3Csvg stroke='%23fff' fill='%23fff' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'%3E%3C/path%3E%3C/svg%3E");
    background-color: var(--primary-50);
    background-position: center;
    background-size: contain;
    border-color: var(--primary-50);
}

.form__remember__me{
    font-size: 14px;
    color: var(--grey-600)
}

.forgot__password{
    font-size: 14px;
    color: var(--grey-1000);
    text-decoration: underline;
    text-underline-offset: 2px;
}


@media (min-width: 1024px) {

    label{
        font-size: 16px;
    }

    .form__primary__button{
        font-size: 22px;
    }

}