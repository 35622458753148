.user__details__containner {
  margin: 24px 40px 46px 40px;
  /* border: 1px solid red; */
  /* background-color: #fff; */
  /* width: 100%; */
  height: 92%;
}
.user__details__show {
  padding-bottom: 1rem;
}
.user__details__show1 {
  line-height: 32px;
  font-weight: 500;
  font-size: 24px;
  padding-bottom: 1rem;
}
.user__details__show2 {
  color: #828282;
  padding-bottom: 1.25rem;
}
.user__details__show3 {
  border: 1px groove #bdbdbd;
}
.bottom__user__container_all {
  background: #fff;
  padding-top: 1.25rem;
}
img.user__image {
  width: 100px;
  height: 100px;
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;
  border-radius: 10px;
}
.bottom__user__container {
  overflow: scroll;
  width: 100%;
  height: 47vh;
}
.bottom__user__underline {
  border: 1px groove #bdbdbd;
}
.bottom__user__div {
  margin: 1rem 1.25rem;
}
