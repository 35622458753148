/* Actual Classes */

:root{
    --auth-frame-width: 42%;
    --red-error: #c02a36;
}

.auth__container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100%;
    overflow: auto;
    background-color: #fff;
}

.auth__container *{
    font-family: 'Outfit';
}

.auth__frame{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
    min-height: 350px;
    /* max-height: 400px; */
    height: auto;
    padding: 2rem;
    background-image: url("assets/images/frame.png");
    background-size: cover;
    background-position: center;
}

.auth__frame img.logo{
    position: relative;
    width: 15%;
    height: auto;
}

.auth__frame img.desc__img{
    width: 70%;
    height: auto;
}

div.auth__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    align-items: flex-start;
    width: 100%;
    padding: 1rem;
}

div.auth__content > *{
    width: 100%;
}

@media (min-width: 1024px) {

    .auth__container{
        flex-direction: row;
        height: 100vh;
    }

    .auth__frame img.logo{
        position: absolute;
        top: 56px;
        left: 40px;
        width: 8%;
    }

    div.auth__frame {
        display: flex;
        width: var(--auth-frame-width);
        min-height: auto;
        max-height: none;
        height: 100%;
    }

    div.auth__content {
        width: calc(100% - var(--auth-frame-width));
        padding: 2rem 4rem;
    }

    .heading{
        font-size: 2rem; /* 32px */
    }

    .subtitle{
        font-size: 22px;
    }

    form label{
        font-size: 16px;
    }

    div.input__box input{
        font-size: 16px;
    }

    button.primary__button{
        font-size: 22px;
    }

    .goback{
        display: flex!important;
    }
}