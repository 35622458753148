
.users__card__header{
    display: none;
}

.users__card__body{
    width: 100%;
    background: var(--white);
    padding: 1rem 0;
}


@media (min-width: 1024px) {

    .users__card__header{
        display: block;
    }
    
    .users__card__body{
        max-height: 28rem;
        overflow-y: auto;
        border-radius: 1rem;
    }

    .users__card__body::-webkit-scrollbar{
        width: 8px;
        height: 8px;
    }
    
    .users__card__body::-webkit-scrollbar-thumb{
        height: 100px;
        border-radius: 99px;
        background: var(--primary-100);
    }
}