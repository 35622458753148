.tabs > *{
    margin-bottom: 1rem;
}

.tabs > *:last-child{
    margin-bottom: 0rem;
}

.tab__header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #D6D9DF;
}

.no__tab__header{
    display: none;
}

.tab__heading{
    position: relative;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-600);
}

.tab__active{
    color: var(--primary-50);
}

.tab__active::after{
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: var(--primary-50);
}

.tab__content{
    display: none;
    width: 100%;
}

.tab__content__active{
    display: block;
}


@media (min-width: 1024px) {
    
    .tab__header{
        gap: 2rem;
    }
    
    .tab__heading{
        width: fit-content;
        padding-left: 0;
        padding-right: 0;
    }
    
    .tab__active::after{
        height: 5px;
        border-radius: 5px 5px 0 0;
    }
}