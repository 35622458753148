.confrim__modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0001;
    backdrop-filter: blur(2px);
    z-index: 99;
}

.confrim__card{
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    width: 40%;
    background: var(--white);
    border-radius: 10px;
    box-shadow: var(--shadow-4);
}

.confrim__card > *{
    padding: 1rem;
    border-bottom: 1px solid var(--grey-50);
}

.confrim__card > *:last-child{
    border-bottom: none;
}

.confirm__card__head{
    font-weight: bold;
}

.confirm__card__body{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    text-align: center;
}

.confirm__card__body .message{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding-top: .5rem;
    font-weight: 600;
    color: #bd2834;
}

.confirm__card__footer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.confirm__card__footer button{
    width: 100%;
    padding: .5rem;
    border-radius: 5px;
    border: 1px solid var(--primary-50);
    color: var(--primary-50);
    font-weight: 600;
}

.confirm__card__footer .cancel__button{
    background: var(--primary-50);
    color: var(--white);
}