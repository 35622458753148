.navbar__box{
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--white);
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.20);
    width: 100%;
}

.navbar__item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .25rem;
    padding: 1rem;
    width: 100%;
}

.icon{
    display: grid;
    place-content: center;
    padding: .25rem 1.25rem;
    border-radius: 999px;
}

.navbar__item__active .icon{
    background: var(--primary-50);
}

.navbar__item__active .icon svg{
    fill: var(--white);
}

.navbar__item__active .icon path{
    fill: var(--white);
}

.navbar__item > span{
    font-size: 12px;
    font-weight: 500;
}

.icon img{
    width: 24px;
    height: 24px;
    border-radius: 999px;
}

@media (min-width: 1024px) {
    .navbar__box{
        display: none;
    }
}