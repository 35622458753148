header {
  background-color: var(--primary-50);
  padding: 1.5rem 1rem;
}

.page__title {
  font-size: 22px;
  color: var(--white);
}

.admin__details {
  display: none;
  align-items: center;
  gap: 1rem;
}

.admin__details img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: top center;
  border-radius: 9999px;
}

.admin__name {
  font-weight: 600;
}

.admin__role {
  font-size: 14px;
  color: var(--grey-600);
}

.menu-icon {
  display: block;
}

@media (min-width: 1024px) {
  header {
    background-color: var(--white);
    padding: 1.875rem 2rem;
  }

  .admin__details {
    display: flex;
  }

  .page__title {
    font-size: 24px;
    color: var(--primary-50);
  }

  .menu__icon {
    display: none;
  }
}
