.overlay{
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: .3;
    background: var(--black);
    z-index: 99;
}

.filter{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    border-radius: 28px 28px 0 0;
    background: var(--white);
    overflow: hidden;
    transition: bottom 200ms;
    display: flex;
    flex-direction: column;
    z-index: 99;
}

.filter__hide{
    bottom: -50%;
}

.filter__head{
    position: sticky;
    top: 0;
    padding: .75rem 1rem;
    border: 1px solid var(--grey-600);
    background: var(--white);
    flex-shrink: 0;
}

.filter__head > *{
    margin-bottom: .5rem;
}

.filter__head > *:last-child{
    margin-bottom: 0;
}

.drag__handle{
    width: 40px;
    height: 4px;
    background: var(--grey-600);
    border-radius: 100px;
    margin: auto;
}

.filter__head__title{
    font-size: 16px;
    font-weight: bold;
}

.filter__body{
    padding: .5rem 0;
    height: 100%;
    overflow: auto;
}


.filter__search__container{
    background: var(--white);
    padding: .5rem;
}

.filter__search__input{
    display: block;
    width: 100%;
    border: 2px solid var(--grey-600);
    border-radius: 5px;
    overflow: hidden;
    padding: .75rem;
    font-size: 15px;
}

.filter__option{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    gap: 2rem;
}

.filter__option__radio{
    width: 20px;
    height: 20px;
    appearance: none;
    border: 2px solid var(--black);
    border-radius: 999px;
}

.filter__option__radio:checked{
    background: var(--black);
}

.filter__option__details{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
}

.icon{
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    overflow: hidden;
}