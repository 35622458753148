.input__group > *{
    margin-bottom: .5rem;
}

.input__group > *:last-child{
    margin-bottom: 0;
}

.input__group > label{
    display: block;
    margin-bottom: .5rem;
}

.input__box{
    display: flex;
    align-items: center;
    gap: .5rem;
    width: 100%;
    border: 1px solid var(--grey-1000);
    border-radius: 10px;
    overflow: hidden;
}

.input__error{
    border-color: var(--red-error);
}

.input__box input{
    box-sizing: border-box;
    padding: 1rem;
    font-size: 14px;
}

.error__msg{
    color: var(--red-error);
    font-size: 12px;
}


@media (min-width: 1024px) {

    .input__box input{
        font-size: 16px;
    }
    
}