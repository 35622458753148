.matches{
    padding: 0;
}

.matches__tab{
    padding: 1.5rem 0;
}

.matches__tab > *{
    margin-bottom: 1rem;
}

.matches__tab > *:last-child{
    margin-bottom: 0;
}

.table{
    padding: 0rem;
}

.table > *{
    margin-bottom: 1rem;
}

.table > *:last-child{
    margin-bottom: 0;
}

.table__head{
    display: none;
}

.table__body{
    counter-reset: match__counter;
    padding: 1rem;
}

.table__header{
    display: none;
}

.table__filter__mobile{
    display: block;
}

.table__row{
    border-radius: 10px;
    overflow: hidden;
}

.table__row > *:nth-child(1){
    display: none;
}

.table__row > *:nth-child(3){
    display: none;
}

.table__row > *:nth-child(4){
    display: none;
}




@media (min-width: 1024px){

    .matches{
        padding: 1.5rem;
    }

    .table{
        background: var(--white);
    }

    .table__head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem;
        background: var(--primary-50);
    }
    
    .table__description{
        color: var(--white);
    }
    
    .table__filter__mobile{
        display: none;
    }
    
    .table__filter__select{
        border: 1px solid var(--grey-600);
        border-radius: 5px;
        padding: .5rem 1rem;
        min-width: 200px;
    }
    
    .table__filter__select option{
        border: 1px solid var(--grey-600);
        border-radius: 5px;
        padding: .5rem 1rem;
        min-width: 200px;
    }

    .table__body{
        padding: 0rem;
    }

    .table__row{
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        align-items: center;
        border-radius: 0;
    }

    .table__row > * {
        padding: 20px 1rem;
    }

    .table__row > *:nth-child(1){
        display: block;
        grid-column-start: span 1;
    }

    .table__row > *:nth-child(2){
        grid-column-start: span 4;
    }

    .table__row > *:nth-child(3){
        display: block;
        grid-column-start: span 3;
    }

    .table__row > *:nth-child(4){
        display: block;
        grid-column-start: span 2;
    }

    .table__row > *:nth-child(5){
        grid-column-start: span 2;
    }
    
    .table__column__title{
        color: var(--grey-600);
        font-size: 14px;
    }
    
    .gameWeek{
        color: var(--primary-50);
        font-weight: bold;
        font-size: 14px;
        text-align: center;
    }

}