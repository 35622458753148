@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;700&family=Roboto:wght@300;400;700&display=swap');

:root {
    /* COLORS  */
    /* primary */
    --primary-50: #008f8f;
    --secondary: #51FFFF;
    /* grey */
    --grey-50: #f2f4f8;
    --grey-300: #dbe0ea;
    --grey-600: #adb3bf;
    --grey-1000: #333538;

    /* rest */
    --black: #222;
    --white: #fff;
    --red-light: #f8d7da;
    --red-dark: #842029;
    --green-light: #d1e7dd;
    --green-dark: #0f5132;

    /* typography */
    --headingFont: "Inconsolata", monospace;
    --bodyFont: "Montserrat", sans-serif;
    --smallText: 0.7em;
    /* rest */
    --backgroundColor: var(--grey-50);
    --textColor: var(--grey-1000);
    --borderRadius: 0.25rem;
    --letterSpacing: 1px;
    --transition: 0.3s ease-in-out all;
    --max-width: 1120px;
    --fixed-width: 600px;
    /* box shadows */
    --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);

    /* Added for more colors included in the design */
    --sidebar-width: 20%;
    --primary-100: #007272; 
    --primary-200: #004F4F;
    --secondary-100: #009C9C;
}

@keyframes spin {
    from{ rotate: 0deg; }
    to{ rotate: 360deg; }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    /* letter-spacing: var(--letterSpacing); */
    font-family: 'Roboto', sans-serif;
}


/* Utility classes */

a {
    color: black;
    text-decoration: none;
}

button{
    background: transparent;
    outline: none;
    border: none;
    font-size: 1rem;
}

button:disabled{
    background: var(--grey-600);
}

.spin{
    animation: spin infinite 500ms linear;
}

input{
    display: inline-block;
    width: 100%;
    border: none;
    outline: none;
}

.w-full{
    width: 100%;
}

.font-bold{
    font-weight: bold;
}

.color-primary-50{
    color: var(--primary-50);
}

.bg-primary-50{
    background-color: var(--primary-50);
}

.bg-primary-100{
    background-color: var(--primary-100);
}

.bg-primary-200{
    background-color: var(--primary-200);
}

.bg-secondary-100{
    background-color: var(--secondary-100);
}

.px-1{
    padding-left: 1rem;
    padding-right: 1rem;
}

.text-center {
    text-align: center;
}

.text-uppercase{
    text-transform: uppercase;
}

.flex{
    display: flex;
}

.flex-col{
    flex-direction: column;
}

.flex-row{
    flex-direction: row;
}

.justify-center{
    justify-content: center;
}

.items-center{
    align-items: center;
}

.justify-between{
    justify-content: space-between!important;
}

.justify-end{
    justify-content: flex-end!important;
}

.gap-0\.5{
    gap: 0.5rem;
}

.gap-1{
    gap: 1rem;
}

.gap-2{
    gap: 2rem;
}

.gap-3{
    gap: 3rem;
}

.space-y-0\.5 > *{
    margin-bottom: 0.5rem;
}

.space-y-1 > *{
    margin-bottom: 1rem;
}

.space-y-1\.5 > *{
    margin-bottom: 1.5rem;
}

.space-y-2 > *{
    margin-bottom: 2rem;
}

.space-y-3 > *{
    margin-bottom: 3rem;
}

.space-y-4 > *{
    margin-bottom: 4rem;
}

*[class*="space-"] > *:last-child{
    margin-bottom: 0;
}

.toast__styles{
    font-family: 'Outfit', 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.toast{
    border-radius: 5px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Outfit', sans-serif;
}

.toast__success{
    background-image: linear-gradient(270deg, #1ca364, #01b185);
}

.toast__error{
    background-image: linear-gradient(270deg, #b33a44, #eb1543);
}

.error-main{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-50);
    color: var(--white);
}

.error-main *{
    font-family: 'Outfit';
    text-align: center;
}

span.error-status{
    color: var(--red-dark);
    font-weight: bold;
    padding: .25rem;
    border-radius: 999px;
}

span.error-page-message{
    font-size: 12px;
    font-style: italic;
}

.goback{
    display: none!important;
    font-size: 14px;
}

.error-main .goback{
    gap: .25rem;
    padding: .25rem 1.5rem;
    color: var(--primary-50);
    background: var(--white);
    font-weight: bold;
    border-radius: 999px;
}

.error-main .goback path{
    stroke: var(--primary-50);
}

.desktop-bell{
    display: none;
}

.mobile-bell{
    display: block;
}

.default__input{
    padding: .5rem 1rem;
    border: var(--grey-600);
    border-radius: 5px;
}


@media (min-width: 1024px) {

    .goback{
        display: flex!important;
    }

    .desktop-bell{
        display: block;
    }

    .mobile-bell{
        display: none;
    }

}