:root{
    /* --sidebar__width: 16.875%; */
    --sidebar__width: 20%;
    /* Added personally for hover effect on non-active navlinks*/
    --primary-100: #007272; 
    --primary-200: #004F4F;
    --secondary-100: #009C9C; 
}

.overlay{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .5;
    z-index: 9;
}

.show__overlay{
    display: block;
}

aside{
    position: fixed;
    top: 0;
    left: -100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
    width: 85%;
    height: 100%;
    background-color: var(--primary-50);
    overflow: auto;
    padding: 2rem 0;
    transition: left 200ms;
    z-index: 99;
    border-radius: 0 1rem 1rem 0;
}

.show__sidebar{
    left: 0%;
}

.sidebar__head{
    padding: 0 2rem;
}

.sidebar__head img{
    display: block;
    margin: 0 auto;
}

.sidebar__content{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    margin: auto;
}

.sidebar__content > *:last-child{
    margin-top: auto;
}

aside hr{
    border-style: solid;
    border-color: var(--grey-300);
    border-radius: 50%;
    width: 80%;
    margin: 0 auto;
}

aside::-webkit-scrollbar{
    width: 5px;
}

aside::-webkit-scrollbar-thumb{
    height: 100px;
    border-radius: 99px;
    background: var(--primary-100);
}

.navlink{
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: transparent;
    color: white;
    padding: .75rem 2rem;
    border-radius: 999px;
}

.navlink:hover:not(.navlink__active){
    background-color: var(--primary-100);
}

.navlink__active{
    background-color: var(--secondary);
    color: black;
}

/* .navlink__active:hover{
    background-color: var(--secondary);
    color: black;
} */
 
.navlink__active svg{
    fill: #000!important;
}

.navlink__active path{
    fill: #000!important;
}

.navlink span{
    display: flex;
    align-items: center;
}

.dropdown__links{
    background-color:  var(--primary-200);
    overflow: hidden;
    max-height: 0px;
    transition: max-height 200ms;
}

.dropdown__icon{
    color: var(--white);
    margin-left: auto;
}

@media (min-width: 1024px) {
    
    .show__overlay{
        display: none!important;
    }

    aside{
        left: 0%;
        width: var(--sidebar-width);
        border-radius: 0;
    }

    .show__sidebar{
        left: 0%;
        width: var(--sidebar-width);
    }

    .sidebar__content{
        width: 100%;
    }

    .navlink{
        border-radius: 0px;
    }
}