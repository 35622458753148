
.page__content{
    /* padding: 2rem; */
    overflow: hidden;
}

.overview__section{
    padding: 1.5rem 0;
}

.overview__card__list{
    display: grid;
    grid-template-columns: repeat(3, 49%);
    gap: .75rem;
    overflow: auto;
    padding: 0 1.5rem;
}

.section__title{
    font-size: 14px;
    padding: 0 1.5rem;
}

.overview__card__list > *{
    color: var(--white);
    border-radius: 1rem;
    min-height: auto;
    padding: 1rem;
}

.users__card__section{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
    width: 100%;
}

.users__card__tab__header{
    display: flex!important;
}


@media (min-width: 1024px) {

    .section__title{
        display: none;
    }

    .overview__card__list{
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        padding: 0 2rem;
    }

    .overview__card__list > *{
        min-height: 168px;
        padding: 2rem;
    }
    
    .users__card__section{
        flex-direction: row;
    }

    .users__card__tab__header{
        display: none!important;
    }

}