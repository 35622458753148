.container{
    padding: 1rem;
}

.create__ads{
    display: flex;
    justify-content: flex-end;
}

.create__button{
    display: inline-flex;
    gap: .5rem;
    align-items: center;
    font-size: small;
    font-weight: 600;
    color: var(--primary-50);
}

.ads__wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding: 1.5rem 1rem;
    background: var(--white);
    border-radius: 15px;
}

.wrapper{
    padding: 1.5rem 1rem;
    background: var(--white);
    border-radius: 15px;
}

.hr {
    border: none;
    border-bottom: 1px solid #000;
    border-color: var(--grey-300);
}

.card{
    position: relative;
    width: 100%;
    padding: .625rem;
    box-shadow: var(--shadow-4);
    border-radius: 10px;
}


.card__media {
    position: relative;
    overflow: hidden;
}

.card__media > *{
    position: relative;
    width: 100%;
    height: 140px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    overflow: hidden;
}

.card__details{
    display: flex;
    justify-content: space-between;
    gap: .5rem;
    padding: 1rem 0;
    font-size: small;
}

.card__company{
    color: var(--primary-50);
    font-weight: 700;
}

.card__date{
    color: var(--grey-600);
    font-weight: 600;
}

.icon{
    position: absolute;
    top: -0.625rem;
    right: -0.625rem;
    z-index: 1;
}

.buttons_group{
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    padding: 0 1rem;
}

.button{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    padding: .75rem 0;
    font-size: small;
    font-weight: 500;
}

.active_button{
    background-color: var(--secondary);
    color: var(--black);
}

.disabled_button{
    background-color: var(--grey-600);
    color: var(--white);
}



.form__fields{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem;
    align-items: center;
}

.form__fields :first-child{
    grid-column: span 4 / span 4;
}

.form__fields :last-child{
    grid-column: span 2 / span 2;
}

.flex__fields{
    display: flex;
    gap: 1rem;
}

.input__field{
    display: block;
    width: 100%;
    padding: 1rem 2rem;
    border: 1px solid var(--grey-600);
    border-radius: 5px;
    /* font-size:; */
}

.drop__overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    backdrop-filter: blur(3px);
}

.upload__field{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    min-height: 250px;
    max-height: 400px;
    border: 2px dashed var(--black);
    border-radius: 20px;
    box-shadow: var(--shadow-4);
    z-index: 999;
}

.upload__text{
    font-size: small;
    text-align: center;
    color: var(--grey-600);
}

.upload__text span{
    color: var(--primary-50);
    font-weight: 600;
}

.uploaded__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    font-size: small;
    text-align: center;
    color: var(--grey-1000);
}

.uploaded__text span:last-child{
    color: var(--red-dark);
    font-weight: 600;
}

input[hidden]{
    display: none;
}

.create__buttons{
    width: 100%;
    margin: auto;
}


.preview__section__heading{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.remove__ad__icon{
    position: absolute;
    top: 0rem;
    left: 0rem;
    display: grid;
    place-content: center;
    width: 2rem;
    height: 2rem;
    background: var(--red-dark);
    border-radius: 5px;
}

.remove__ad__icon path{
    fill: var(--red-light);
}






@media (min-width: 1024px) {
    
    .container{
        padding: 1rem;
    }
    
    .ads__wrapper{
        flex-direction: column;
        border-radius: 10px;
    }
    
    .create__buttons{
        width: 50%;
    }
    
}