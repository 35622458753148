.single__user{
    display: grid;
    grid-template-columns: 4fr 1fr 1.5fr;
    width: 100%;
    align-items: center;
    gap: 1rem
}

.single__user img{
    object-fit: cover;
    object-position: top center;
    width: 56px;
    height: 56px;
    border-radius: var(--borderRadius);
}

@media (min-width: 1024px) {
    .single__user{
        padding: 0 1rem;
    }
}