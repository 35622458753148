.container{
    padding: 1rem;
}

.tab_header{
    gap: 3rem;
}

.textarea{
    display: block;
    width: 100%;
    height: 500px;
    resize: none;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid var(--grey-300);
    background: var(--white);
}


.buttons_group{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.button{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 1rem 3rem;
    font-size: smaller;
    font-weight: 500;
}

.active_button{
    background-color: var(--secondary);
    color: var(--black);
}

.disabled_button{
    background-color: var(--grey-600);
    color: var(--white);
}

.preview{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: var(--white);
    border-radius: 10px;
    box-shadow: var(--shadow-3);
    padding: 1rem;
}

.preview > *{
    border-bottom: 1px solid var(--grey-600);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.preview > *:last-child{
    border-bottom: none;
}

.preview h1{
    font-size: 22px;
}

.hide__block{
    display: none;
}



@media (min-width: 1024px) {

    .container{
        padding: 2.5rem;
    }

    .buttons_group{
        gap: 3rem;
        justify-content: flex-start;
    }
    
    .button{
        padding: 1rem 4rem;
        font-size: small;
    }

    .preview{
        padding: 2rem;
    }

}