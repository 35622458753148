.select__box{
    position: relative;
    position: relative;
}

.select__current__item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .5rem;
    background: var(--white);
    border: 1px solid var(--grey-600);
    border-radius: 5px;
    padding: .5rem 1rem;
    min-width: 250px;
}

.select__search__container{
    background: var(--white);
    padding: .5rem;
}

.select__search__input{
    display: block;
    width: 100%;
    border: 2px solid var(--grey-600);
    border-radius: 3px;
    overflow: hidden;
    padding: .75rem;
    font-size: 15px;
}

.select__options{
    position: absolute;
    top: 100%;
    left: 0;
    background: var(--white);
    border-radius: 5px;
    padding: .5rem 0;
    min-width: 100%;
    max-height: 400px;
    overflow: auto;
    box-shadow: var(--shadow-1);
}

.option{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
    width: 100%;
    min-width: 100%;
    color: var(--grey-1000);
    padding: 1rem 1.5rem;
}

.icon{
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    overflow: hidden;
}