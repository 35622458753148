.card__label{
    font-size: 8px;
}

.card__value{
    font-size: 14px;
}

.card__icon{
    display: grid;
    place-content: center;
    background: var(--white);
    border-radius: 9999px;
    width: 32px;
    height: 32px;
}

.card__icon > svg{
    width: 12px;
}


@media (min-width: 1024px) {

    .card__label{
        font-size: 16px;
    }
    
    .card__value{
        font-size: 22px;
    }

    .card__icon{
        width: 58px;
        height: 58px;
    }

    .card__icon > svg{
        width: auto;
    }
    
}