.match__card{
    box-shadow: var(--shadow-3);
    border-radius: 10px;
    overflow: hidden;
    background: var(--white);
    margin-bottom: 1rem;
}

.match__body{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .25rem;
    width: 100%;
    padding: 1rem;
}

.match__contestant{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .375rem;
    text-align: center;
}

.logo{
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.name{
    font-size: 11px;
    font-weight: 500;
    color: var(--grey-1000);
}

.match__playtime{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .25rem;
    text-align: center;
}

.time{
    font-weight: 600;
}

.date{
    font-size: 11px;
    color: var(--grey-600);
}

.match__button{
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    background: var(--grey-600);
}

.match__remove__button{
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    background: var(--red-dark);
    color: var(--white);
}

.selected{
    background: var(--secondary);
}